.map_container {
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.map_container:hover {
  cursor: pointer !important;
  user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}