.app_container {
  // background-color: red;
}

.inner_text_line_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 29.89px;
}

.inner_text_line_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

}

.inner_text_line_3 {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 15px;
}

.inner_text_line_4 {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner_text_line_5 {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner_text_line_6 {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner_text_line_7 {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner_text_line_8 {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inner_text_line_9 {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inner_text_line_10 {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}