.rhap_container {
    padding: 0;
    box-shadow: none;
}

.rhap_controls-section {
    display: none;
    width: fit-content;

    .rhap_additional-controls {
        display: none;
    }

    .rhap_main-controls {
        flex: 0;
    }

    .rhap_volume-controls {
        display: none;
    }
}