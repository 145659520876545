.form_container {
    width: 100%;
    height: 100%;
    margin-top: 60px;
}

.form_inner_div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.form_name_container {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form_message_container {
    flex: 1;
    width: 100%;
}
