.audio_player_outer_container {
    padding: 24px 16px 16px 16px;
    height: 92px;
    display: flex;
    flex-direction: column;
}

.audio_player_container {

    display: flex;
    flex: 1;

    justify-content: space-between;
}

.audio_player_container_left {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}

.music_title {
    font-weight: 500;
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    align-self: flex-start;
}

.music_desc {
    margin-top: 6px;
    font-size: 14px;
    flex-grow: 1;
    align-content: flex-end;
}

.music_length {
    flex: 1;
    font-size: 14px;
    align-content: end;

}


.audio_player_container_right {
    align-self: flex-end;
}

.floating_play_button {
    z-index: 200;
    position: fixed;
    top: 16px;
    left: calc(50vw - 300px + 16px)
}


@media (max-width: 600px) {
    .floating_play_button {
        position: fixed;
        top: 16px;
        left: 16px;
    }
  }